import { useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import SLUGS from 'resources/slugs';
import CURRENCIES from 'resources/currencies';

function useUpdateClientSettings() {
    const { push } = useHistory();
    const queryCache = useQueryClient();
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/client_settings', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onSuccess: async () => {
            await queryCache.invalidateQueries({ queryKey: 'clientSettings' });
            await queryCache.refetchQueries({ queryKey: 'clientSettings' });
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['update_client_settings'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

export function useUpdateCollectRecurringPayments() {
    const { push } = useHistory();
    const queryCache = useQueryClient();
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/client_settings/collect_recurring_payments', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onSuccess: async () => {
            await queryCache.invalidateQueries('client_settings');
            await queryCache.refetchQueries(['client_settings']);
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['client_settings/collect_recurring_payments'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

export function useMultiOrgUsers({ enabled = false }) {
    const { push } = useHistory();
    const queryCache = useQueryClient();
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/multi_org_users/change_organization', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const {
        mutate: changeOrganization,
        isLoading: isLoadingMutation,
        data: mutationData
    } = useMutation(post, {
        onSuccess: async () => {
            await queryCache.invalidateQueries('client_settings');
            await queryCache.refetchQueries(['client_settings']);
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['multi_org_users'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });

    const {
        data = {},
        error,
        isLoading: isLoadingQuery
    } = useQuery(['multi_org_users'], () => fetchAuth('/multi_org_users/get_organizations'), {
        retry: false,
        refetchOnMount: false,
        enabled,
        onSuccess: (data) => {
            if (!data) {
                Sentry.captureException(new Error('empty result'), {
                    tags: ['multi_org_users']
                });
                push(SLUGS.error404);
            }
        },
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['multi_org_users']
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });

    return {
        response: data,
        mutationData,
        error,
        isLoading: isLoadingMutation || isLoadingQuery,
        changeOrganization
    };
}

function useInviteUser() {
    const { push } = useHistory();
    const queryCache = useQueryClient();
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/invite_user', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onSuccess: async () => {
            await queryCache.invalidateQueries({ queryKey: 'clientSettings' });
            await queryCache.refetchQueries({ queryKey: 'clientSettings' });
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['invite_user'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

function useGetClientSettings({ refetchOnMount = false, refetchInterval, params, skipFetch } = {}) {
    const fetchAuth = useFetchAuth();
    const {
        push,
        location: { pathname }
    } = useHistory();
    const refClientName = useRef();
    let url = '/client_settings';
    if (params !== '') {
        url += `?params=${params},default_currency`;
    }
    const {
        data = {},
        error,
        isLoading
    } = useQuery(['clientSettings', params], () => fetchAuth(url), {
        retry: 3,
        retryDelay: 1000,
        refetchOnMount,
        refetchInterval,
        enabled: !skipFetch && params !== '',
        onSuccess: (data) => {
            if (!data) {
                push(SLUGS.error404);
            }
            if (
                data &&
                data.ai_assist_settings &&
                data.ai_assist_settings.ai_assist_settings === null
            ) {
                data.ai_assist_settings.ai_assist_settings = undefined;
            }
        },
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['clientSettings', params]
            });
            console.log(err);
            if (err.code && err.code === 'UserNotFoundException') {
                localStorage.clear();
                push(SLUGS.home);
            } else {
                push(SLUGS.error404);
            }
        }
    });

    useEffect(() => {
        if (
            refClientName &&
            data &&
            pathname.indexOf('/campaigns') === 0 &&
            refClientName.current !== data.client_name
        ) {
            if (!!refClientName.current) {
                push(SLUGS.home);
            }
            refClientName.current = data.client_name;
        }
    }, [data, push, pathname]);

    return {
        isLoading,
        error,
        data
    };
}

export default function useClientSettings({
    refetchOnMount = false,
    refetchInterval,
    params = '',
    skipFetch = false
} = {}) {
    const {
        data: resultUpdateClientSettings,
        error: errorUpdateClientSettings,
        isLoading: isLoadingUpdateClientSettings,
        mutate: UpdateClientSettings
    } = useUpdateClientSettings();
    const {
        data: resultInviteUser,
        error: errorInviteUser,
        isLoading: isLoadingInviteUser,
        mutate: inviteUser
    } = useInviteUser();

    const {
        data = {},
        error,
        isLoading: isLoadingGetClientSettings
    } = useGetClientSettings({ refetchOnMount, refetchInterval, params, skipFetch });
    const isLoading =
        isLoadingGetClientSettings || isLoadingUpdateClientSettings || isLoadingInviteUser;
    const currency = CURRENCIES[data.default_currency] || CURRENCIES.AUD;

    return {
        isLoading,
        error,
        data,
        currency,
        resultUpdateClientSettings,
        errorUpdateClientSettings,
        resultInviteUser,
        errorInviteUser,
        inviteUser,
        UpdateClientSettings
    };
}
