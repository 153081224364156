import {
    IconBell,
    IconCreditCard,
    IconDataHub,
    IconDonors,
    IconFundraisingIntelligence,
    IconGini,
    IconHelp,
    IconNotes,
    IconPlus,
    IconPredictions,
    IconSettings
} from 'assets/icons';
import Badge from 'components/badge';
import ButtonComponent from 'components/form/ButtonComponent';
import LoadingComponent from 'components/loading';
import AccountItemComponent from 'components/sidebar/AccountItemComponent';
import LogoComponent from 'components/sidebar/LogoComponent';
import Menu from 'components/sidebar/MenuComponent';
import MenuItem from 'components/sidebar/MenuItemComponent';
import useAIAssist from 'hooks/useAIAssist';
import useCampaignSuggestions from 'hooks/useCampaignSuggestion';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import usePredictionInsights from 'hooks/usePredictionInsights';
import useProducts from 'hooks/useProducts';
import useUpgradeAccount from 'hooks/useUpgradeAccount';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SECTIONS from 'resources/sections';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';

const P2PPropensities = [
    'Peer-to-Peer to Direct Grade',
    'Fundraiser to Direct Grade',
    'Direct to Fundraiser Grade'
];

const STRIPE_BILLING_URL = 'https://billing.stripe.com/p/login/bIYg1ecGh4cG6licMM';

const useStyles = createUseStyles((theme) => ({
    helpLink: {
        fontWeight: 400,
        textDecoration: 'underline',
        letterSpacing: '-0.35px',
        color: '#ffffff',
        '&:hover': {
            color: '#ffffff'
        }
    },
    upgradeAiAssistLink: {
        fontWeight: '400',
        fontSize: 18,
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            color: 'white',
            textDecoration: 'underline'
        }
    },
    headerContainer: {
        backgroundColor: 'white',
        padding: 14,
        columnGap: 10,
        marginTop: -14
    },
    smallTitle: {
        fontSize: 22,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '-0.45px'
    },
    description: {
        textWrap: 'wrap',
        color: '#ffffff',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 330,
        lineHeight: 'normal',
        letterSpacing: '-0.45px'
    },
    separator: {
        borderBottom: `2px solid ${theme.color.grey2}`,
        maxWidth: '100%'
    }
}));

function SidebarComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { push } = useHistory();
    const {
        data: templates,
        template_groups: templateGroups,
        isLoading: isLoadingAIAssist
    } = useAIAssist();
    const {
        isUsingDemoDataForPropensities,
        isLoading: isLoadingProducts,
        aiAssistDaysRemaining,
        hasAIAssistPaidUser,
        hasAIAssistPaidClient,
        isAIAssistAvailable,
        isUsingFIFree,
        hasFIPaid,
        hasConnectionsEnabled,
        isDemoClient,
        crmHasFiFree,
        hasAIAnalystEnabled: showAIAnalyst,
        hasAIAssistEnabled,
        hasCreateCampaignEnabled,
        showMidValueReport,
        showMajorDonorReport,
        hasDuplicateDetectionEnabled
    } = useProducts();
    const { isLoading: isLoadingCRMTypes, data: crm_types_prop = [] } = useCRMTypes();
    const { isLoading: isLoadingUpgradeAccount, createClient } = useUpgradeAccount();

    const { isLoading: isLoadingCampaignSizeSuggestion, campaignSuggestions } =
        useCampaignSuggestions();

    const {
        isLoading: isLoadingPredictionInsights,
        data: { insight_items = [] }
    } = usePredictionInsights(isUsingDemoDataForPropensities);

    const crm_types = crm_types_prop.filter((t) => !!t.onboarding_order);
    const settingsParams = [
        'client_name',
        'declared_crm_type',
        'org_plan_includes',
        'org_plan_excludes',
        'has_connection_issue',
        'is_global_parent',
        'crm_type',
        'email'
    ];
    const {
        data: {
            declared_crm_type,
            org_plan_includes = [],
            org_plan_excludes = [],
            is_global_parent,
            crm_type,
            has_connection_issue
        } = {},
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });

    const showDragAndDropMenu = ['drag_and_drop'].includes(crm_type);

    const isLoading =
        isLoadingAIAssist ||
        isLoadingProducts ||
        isLoadingCRMTypes ||
        isLoadingCampaignSizeSuggestion ||
        isLoadingPredictionInsights ||
        isLoadingUpgradeAccount ||
        isLoadingClientSettings;
    const propensities = [
        ...org_plan_includes.map((item) => ({ value: item, include: true })),
        ...org_plan_excludes.map((item) => ({ value: item, include: false }))
    ];
    const propensitiesList = propensities
        .filter((item) => item.include && item.value !== '')
        .map((item) => item.value);

    const isP2PonlyClient =
        propensitiesList.filter((item) => !P2PPropensities.includes(item)).length === 0;

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    let predictItems = [
        {
            id: SECTIONS.campaignSize,
            parentPath: SECTIONS.predict,
            fullPath: SLUGS.campaignSize,
            title: 'Campaign Size Suggestions',
            isDisabledItem: isP2PonlyClient,
            tooltipText: isP2PonlyClient ? (
                <div>
                    Campaign Suggestions <br></br>is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: isP2PonlyClient ? 'lock' : '',
            onClick: () => onClick(SLUGS.campaignSize),
            hideItem:
                !!campaignSuggestions &&
                Array.isArray(campaignSuggestions) &&
                campaignSuggestions.length === 0
                    ? true
                    : false
        },
        {
            id: SECTIONS.campaigns,
            parentPath: SECTIONS.predict,
            fullPath: SLUGS.campaignResults,
            title: 'Campaign Results',
            isDisabledItem: isP2PonlyClient,
            tooltipText: isP2PonlyClient ? (
                <div>
                    Campaign Results <br></br>is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: isP2PonlyClient ? 'lock' : '',
            onClick: () => onClick(SLUGS.campaignResults)
        },
        {
            id: SECTIONS.predictions,
            parentPath: SECTIONS.predict,
            fullPath: SLUGS.predictions,
            title: 'Prediction Insights',
            isDisabledItem: isP2PonlyClient,
            tooltipText: isP2PonlyClient ? (
                <div>
                    Prediction Insights <br></br>is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: isP2PonlyClient ? 'lock' : '',
            onClick: () => onClick(SLUGS.predictions),
            hideItem: insight_items.length === 0 ? true : false
        },
        {
            id: SECTIONS.performance,
            parentPath: SECTIONS.predict,
            fullPath: SLUGS.performance,
            title: 'Model Performance',
            isDisabledItem: isP2PonlyClient,
            tooltipText: isP2PonlyClient ? (
                <div>
                    Model Performance <br></br>is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: isP2PonlyClient ? 'lock' : '',
            onClick: () => onClick(SLUGS.performance)
        }
    ];

    predictItems.push({
        id: SECTIONS.campaignInsights,
        parentPath: SECTIONS.predict,
        fullPath: SLUGS.campaignInsights,
        title: (
            <div className='flex flex-row items-center gap-2'>
                Campaign Insights <Badge className='px-3'>BETA</Badge>
            </div>
        ),
        isDisabledItem: isP2PonlyClient,
        tooltipText: isP2PonlyClient ? (
            <div>
                Campaign Insights <br></br>is not available on your plan.
                <p>
                    <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                        Contact sales
                    </a>
                    &nbsp; to upgrade.
                </p>
            </div>
        ) : (
            ''
        ),
        tooltipType: isP2PonlyClient ? 'lock' : '',
        onClick: () => onClick(SLUGS.campaignInsights)
    });

    const donorsAndSegmentsItems = [
        {
            id: SECTIONS.donors,
            parentPath: SECTIONS.donorsAndSegments,
            fullPath: SLUGS.baseDonorView,
            title: 'Contact View',
            onClick: () =>
                onClick(
                    convertSlugToUrl(SLUGS.donorView, {
                        donorId: '',
                        filters: ''
                    })
                )
        },
        {
            id: SECTIONS.donorList,
            parentPath: SECTIONS.donorsAndSegments,
            title: 'List Builder',
            fullPath: SLUGS.donorList,
            onClick: () => onClick(SLUGS.donorList)
        },
        {
            id: SECTIONS.savedDonorLists,
            parentPath: SECTIONS.donorsAndSegments,
            title: 'Saved Lists',
            fullPath: SLUGS.savedDonorLists,
            onClick: () => onClick(SLUGS.savedDonorLists)
        },
        {
            id: SECTIONS.donorsAndSegmentsSmartAudience,
            parentPath: SECTIONS.donorsAndSegments,
            title: (
                <div className='flex flex-row items-center gap-2'>
                    Smart Audiences <Badge className='px-3'>BETA</Badge>
                </div>
            ),
            fullPath: SLUGS.donorsAndSegmentsSmartAudience,
            onClick: () => onClick(SLUGS.donorsAndSegmentsSmartAudience)
        },
        {
            id: SECTIONS.donorsAndSegmentsMajorDonor,
            parentPath: SECTIONS.donorsAndSegments,
            fullPath: SLUGS.donorsAndSegmentsMajorDonor,
            title: 'Major donor program summary',
            isDisabledItem: !showMajorDonorReport,
            tooltipText: !showMajorDonorReport ? (
                <div>
                    Major donor program summary <br></br>is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: !showMajorDonorReport ? 'lock' : '',
            onClick: () => onClick(SLUGS.donorsAndSegmentsMajorDonor)
        },
        {
            id: SECTIONS.donorsAndSegmentsMidValue,
            parentPath: SECTIONS.donorsAndSegments,
            fullPath: SLUGS.donorsAndSegmentsMidValue,
            title: 'Mid-level donor program summary',
            isDisabledItem: !showMidValueReport,
            tooltipText: !showMidValueReport ? (
                <div>
                    Mid-level donor program summary <br></br> is not available on your plan.
                    <p>
                        <a className={classes.helpLink} href={'mailto:sales@dataro.io'}>
                            Contact sales
                        </a>
                        &nbsp; to upgrade.
                    </p>
                </div>
            ) : (
                ''
            ),
            tooltipType: !showMidValueReport ? 'lock' : '',
            onClick: () => onClick(SLUGS.donorsAndSegmentsMidValue)
        }
    ];

    let dataHubItems = [];

    if (!is_global_parent) {
        dataHubItems.push({
            id: SECTIONS.connections,
            title: showDragAndDropMenu ? 'Drag and Drop' : 'Connections',
            fullPath: showDragAndDropMenu ? SLUGS.dragAndDrop : SLUGS.connections,
            onClick: () =>
                showDragAndDropMenu ? onClick(SLUGS.dragAndDrop) : onClick(SLUGS.connections),
            isAlertIcon: has_connection_issue
        });
    }
    dataHubItems.push({
        id: SECTIONS.dataInspector,
        title: 'Data Inspector',
        fullPath: SLUGS.dataInspector,
        onClick: () => onClick(SLUGS.dataInspector)
    });
    dataHubItems.push({
        id: SECTIONS.dataRecordMapping,
        title: 'Data Mapping',
        fullPath: SLUGS.dataRecordMapping,
        onClick: () => onClick(SLUGS.dataRecordMapping)
    });
    dataHubItems.push({
        id: SECTIONS.campaignTagging,
        title: 'Campaign Tagging',
        fullPath: SLUGS.campaignTagging,
        onClick: () => onClick(SLUGS.campaignTagging)
    });
    if (hasDuplicateDetectionEnabled) {
        dataHubItems.push({
            id: SECTIONS.duplicateDetection,
            title: 'Duplicate Detection',
            fullPath: SLUGS.duplicateDetection,
            onClick: () => onClick(SLUGS.duplicateDetection)
        });
    }

    let FiItems = [
        {
            id: SECTIONS.fundraisingIntelligenceDashboards,
            title: 'Dashboards',
            fullPath: SLUGS.fundraisingIntelligenceDashboard,
            onClick: () => onClick(convertSlugToUrl(SLUGS.fundraisingIntelligenceDashboard))
        },
        {
            id: SECTIONS.fundraisingIntelligenceReports,
            title: 'Reports',
            fullPath: SLUGS.fundraisingIntelligence,
            onClick: () => onClick(SLUGS.fundraisingIntelligence)
        }
    ];

    FiItems.push({
        id: SECTIONS.fundraisingInsights,
        title: (
            <div className='flex flex-row items-center gap-2'>
                Fundraising Insights <Badge className='px-3'>BETA</Badge>
            </div>
        ),
        fullPath: SLUGS.fundraisingInsights,
        onClick: () => onClick(SLUGS.fundraisingInsights)
    });

    FiItems.push({
        id: `${SECTIONS.fundraisingIntelligence}_settings`,
        title: 'Settings',
        fullPath: SLUGS.baseFundraisingIntelligenceSettings,
        onClick: () => onClick(SLUGS.fundraisingIntelligenceSettings, { section: '' })
    });

    const aiAssistItems =
        !templates || isLoading
            ? []
            : templateGroups.map((groupName) => ({
                  id: `${SECTIONS.aiAssist}_${groupName.split(' ').join('_')}`,
                  parentPath: SECTIONS.aiAssist,
                  title: groupName,
                  isDisabledItem: !isAIAssistAvailable,
                  showDisabledItem: !isAIAssistAvailable,
                  colorOnHover: true,
                  items: templates
                      .filter((t) => t.template_group === groupName)
                      .map((template) => ({
                          id: `${SECTIONS.aiAssist}_${template.template_id}`,
                          parentPath: SECTIONS.aiAssist,
                          title: template.template_title,
                          templateGroup: template.template_group,
                          fullPath: `/${SECTIONS.aiAssist}/${template.template_id}`,
                          onClick: () =>
                              onClick(SLUGS.aiAssist, {
                                  generator_type: template.template_id
                              }),
                          isDisabledItem: !isAIAssistAvailable
                      }))
              }));
    aiAssistItems.push({
        id: `${SECTIONS.aiAssist}_settings`,
        parentPath: SECTIONS.aiAssist,
        title: 'Settings',
        fullPath: SLUGS.aiAssistSettings,
        isDisabledItem: !isAIAssistAvailable,
        showDisabledItem: !isAIAssistAvailable,
        onClick: () => onClick(SLUGS.aiAssistSettings)
    });

    if (hasAIAssistPaidClient || hasAIAssistPaidUser) {
        aiAssistItems.push({
            id: `${SECTIONS.aiAssist}_billing`,
            parentPath: SECTIONS.aiAssist,
            title: (
                <div className='flex flex-row items-center gap-2'>
                    <IconCreditCard fill={isAIAssistAvailable ? '#5D5D5D' : 'gray'} /> Billing
                </div>
            ),
            isDisabledItem: !isAIAssistAvailable,
            showDisabledItem: !isAIAssistAvailable,
            fullPath: STRIPE_BILLING_URL,
            onClick: onBillingButtonClick
        });
    }

    function onScheduleDemoClick() {
        push({ search: '?showCalendar=true' });
    }

    function onStartFreeTrialClick() {
        if (isDemoClient) {
            const crms_with_fi_free = crm_types
                .filter((t) => t.self_onboarding_fi_free === true)
                .map((t) => t.crm_type);
            const selectedCRMIsIncluded = crms_with_fi_free.includes(declared_crm_type);
            // TODO: calculate this on the backend (selectedCRMIsIncluded)

            if (selectedCRMIsIncluded) {
                createClient();
            } else {
                push({ search: '?showCalendar=true' });
            }
        } else {
            push(convertSlugToUrl(SLUGS.accountSetup, { step: 0 }));
        }
    }

    function onUpgradeButtonClick() {
        window.open('https://buy.stripe.com/8wMbLD1dFcY186IeUW');
    }

    function onBillingButtonClick() {
        window.open(STRIPE_BILLING_URL);
    }

    const classForMenuItemHeader =
        'flex flex-col justify-between text-soft-black bg-transparent pt-[14px] pb-[14px] pl-[14px] pr-[14px] gap-y-[8px]';

    return (
        <Menu>
            <div className='pt-[30px] pb-[30px]'>
                <LogoComponent onClick={() => onClick(SLUGS.home)} />
            </div>
            <MenuItem
                id={SECTIONS.predict}
                title='Predict'
                icon={IconPredictions}
                fullPath={SLUGS.predict}
                onClick={() => onClick(SLUGS.predict)}
                items={predictItems}
                MenuHeader={
                    !isUsingDemoDataForPropensities
                        ? undefined
                        : () => (
                              <>
                                  <div className={classForMenuItemHeader}>
                                      <div className='whitespace-nowrap'>Now using demo data</div>
                                      <ButtonComponent
                                          width={120}
                                          style={{
                                              height: 30,
                                              fontSize: 15,
                                              padding: '2px 3px',
                                              whiteSpace: 'nowrap'
                                          }}
                                          onClick={onScheduleDemoClick}
                                      >
                                          Schedule demo
                                      </ButtonComponent>
                                  </div>
                                  <div className={classes.separator} />
                              </>
                          )
                }
            />

            {hasCreateCampaignEnabled && (
                <MenuItem
                    id={SECTIONS.createCampaign}
                    fullPath={SLUGS.createCampaignBase}
                    title='Create a campaign'
                    icon={IconPlus}
                    onClick={() => onClick(SLUGS.createCampaign, { step: 1 })}
                />
            )}

            <MenuItem
                id={SECTIONS.fundraisingIntelligence}
                title={is_global_parent ? 'Global Intelligence' : 'Fundraising Intelligence'}
                icon={IconFundraisingIntelligence}
                fullPath={SLUGS.fundraisingIntelligenceDashboard}
                onClick={() => onClick(SLUGS.fundraisingIntelligenceDashboard)}
                items={FiItems}
                MenuHeader={
                    !hasFIPaid
                        ? () => (
                              <>
                                  <div className={classForMenuItemHeader}>
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                          {isUsingFIFree
                                              ? 'You are on FREE tier'
                                              : 'Now using demo data'}
                                      </span>
                                      <ButtonComponent
                                          width={120}
                                          style={{
                                              height: 30,
                                              fontSize: 15,
                                              padding: '2px 3px',
                                              whiteSpace: 'nowrap'
                                          }}
                                          onClick={() => {
                                              if (isUsingFIFree) {
                                                  onScheduleDemoClick();
                                              } else {
                                                  onStartFreeTrialClick();
                                              }
                                          }}
                                      >
                                          {isUsingFIFree
                                              ? 'Upgrade now'
                                              : crmHasFiFree
                                              ? 'Start free trial'
                                              : 'Schedule demo'}
                                      </ButtonComponent>
                                  </div>
                                  <div className={classes.separator} />
                              </>
                          )
                        : undefined
                }
            />

            <MenuItem
                id={SECTIONS.donorsAndSegments}
                title='Donors and Segments'
                icon={IconDonors}
                fullPath={SLUGS.donorsAndSegments}
                onClick={() => onClick(SLUGS.donorsAndSegments)}
                items={donorsAndSegmentsItems}
                MenuHeader={
                    !isUsingDemoDataForPropensities
                        ? undefined
                        : () => (
                              <>
                                  <div className={classForMenuItemHeader}>
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                          Now using demo data
                                      </span>
                                      <ButtonComponent
                                          width={120}
                                          style={{
                                              height: 30,
                                              fontSize: 15,
                                              padding: '2px 3px',
                                              whiteSpace: 'nowrap'
                                          }}
                                          onClick={onScheduleDemoClick}
                                      >
                                          Schedule demo
                                      </ButtonComponent>
                                  </div>
                                  <div className={classes.separator} />
                              </>
                          )
                }
            />

            {hasAIAssistEnabled && (
                <MenuItem
                    id={SECTIONS.aiAssist}
                    title='AI Assist'
                    icon={IconNotes}
                    items={aiAssistItems}
                    MenuHeader={
                        hasAIAssistPaidClient || hasAIAssistPaidUser
                            ? undefined
                            : () => (
                                  <>
                                      <div className={classForMenuItemHeader}>
                                          {aiAssistDaysRemaining ? (
                                              <span style={{ whiteSpace: 'nowrap' }}>
                                                  {aiAssistDaysRemaining} days remaining
                                              </span>
                                          ) : (
                                              <span style={{ whiteSpace: 'nowrap' }}>
                                                  Trial complete
                                              </span>
                                          )}
                                          <ButtonComponent
                                              width={120}
                                              style={{
                                                  height: 30,
                                                  fontSize: 15,
                                                  padding: '2px 3px',
                                                  whiteSpace: 'nowrap'
                                              }}
                                              className='text-dataro-black'
                                              onClick={onUpgradeButtonClick}
                                          >
                                              Upgrade now
                                          </ButtonComponent>
                                      </div>
                                      <div className={classes.separator} />
                                  </>
                              )
                    }
                />
            )}
            <MenuItem
                id={SECTIONS.dataHub}
                title='Data Hub'
                icon={IconDataHub}
                fullPath={SLUGS.dataHub}
                onClick={() => onClick(SLUGS.dataHub)}
                items={dataHubItems}
                MenuHeader={
                    hasConnectionsEnabled
                        ? undefined
                        : () => (
                              <>
                                  <div className={classForMenuItemHeader}>
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                          Now using demo data
                                      </span>
                                      <ButtonComponent
                                          width={120}
                                          style={{
                                              height: 30,
                                              fontSize: 15,
                                              padding: '2px 3px',
                                              whiteSpace: 'nowrap'
                                          }}
                                          onClick={onScheduleDemoClick}
                                      >
                                          Schedule demo
                                      </ButtonComponent>
                                  </div>
                                  <div className={classes.separator} />
                              </>
                          )
                }
            />

            {showAIAnalyst && (
                <MenuItem
                    id={SECTIONS.aiAnalyst}
                    title={
                        <div className='flex flex-col max-w-[278px]'>
                            <span className={classes.smallTitle}>Gini</span>
                            <div>
                                <hr />
                            </div>
                            <div className={classes.description}>
                                Gini is a data analyst who can answers all your data questions in
                                plain english.
                            </div>
                        </div>
                    }
                    icon={IconGini}
                    fullPath={SLUGS.aiAnalyst}
                    onClick={() => onClick(SLUGS.aiAnalyst)}
                    MenuHeader={() => (
                        <>
                            <div className={classForMenuItemHeader}>
                                <span style={{ whiteSpace: 'nowrap' }}>Currently in Beta</span>
                                <ButtonComponent
                                    width={120}
                                    style={{
                                        height: 30,
                                        fontSize: 15,
                                        padding: '2px 3px',
                                        whiteSpace: 'nowrap'
                                    }}
                                    onClick={onScheduleDemoClick}
                                >
                                    Learn more
                                </ButtonComponent>
                            </div>
                            <div className={classes.separator} />
                        </>
                    )}
                />
            )}
            <div style={{ paddingTop: 25, paddingBottom: 2 }} />
            <MenuItem
                id={SECTIONS.support}
                title=''
                icon={IconHelp}
                fullPath={SLUGS.support}
                onClick={() => onClick(SLUGS.support)}
            />
            <MenuItem
                id={SECTIONS.settings}
                title=''
                icon={IconSettings}
                fullPath={SLUGS.settingsBase}
                onClick={() => onClick(convertSlugToUrl(SLUGS.settings, { section: '' }))}
            />
            <MenuItem
                id='dataro-frill-widget'
                title=''
                icon={IconBell}
                className='frill-container'
            />
            <AccountItemComponent />
            <div style={{ paddingBottom: 13 }} />
            <LoadingComponent loading={isLoading} />
        </Menu>
    );
}

export default SidebarComponent;
