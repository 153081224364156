import React, { forwardRef } from 'react';
import { Column, Row } from 'components/simpleFlexbox';
import TooltipComponent from 'components/popover/TooltipComponent';
import { SpinnerComponent } from 'components/loading';
import './input.css';

function InputComponent(
    {
        className,
        label,
        name,
        type = 'text',
        placeholder,
        errorMessage,
        showErrorMessageBelow,
        styleErrorContainer,
        textArea,
        tooltipInfo,
        isLoading,
        disabled,
        ...otherProps
    },
    ref
) {
    const containerClassName = [
        'input-text-container',
        className,
        disabled
            ? 'input-text-container-disabled'
            : errorMessage
            ? !styleErrorContainer
                ? 'input-text-container-error'
                : styleErrorContainer
            : ''
    ].join(' ');

    const element = React.createElement(!textArea ? 'input' : 'textarea');

    return (
        <Column className={containerClassName}>
            {label && (
                <Row wrap={false} style={{ columnGap: 6 }}>
                    <label htmlFor={name}>{label}</label>
                    {tooltipInfo && <TooltipComponent message={tooltipInfo} />}
                </Row>
            )}
            {isLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${textArea ? (otherProps.rows || 0) * 12 : 56}px`,
                        left: `calc(50% - ${!textArea ? 14 : 30}px)`
                    }}
                >
                    <SpinnerComponent
                        loading
                        width={!textArea ? 28 : 60}
                        secondaryColor={'white'}
                    />
                </div>
            )}
            <element.type
                type={type || 'text'}
                placeholder={placeholder}
                autoComplete='off'
                ref={ref}
                name={name}
                disabled={disabled || isLoading}
                {...otherProps}
            />
            {errorMessage && (
                <>
                    {!showErrorMessageBelow ? (
                        <div style={{ position: 'relative' }} className='error-message'>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 18
                                }}
                            >
                                <TooltipComponent type='error' message={errorMessage} />
                            </div>
                        </div>
                    ) : (
                        <span className='input-text-container-error-message'>{errorMessage}</span>
                    )}
                </>
            )}
        </Column>
    );
}

export default forwardRef(InputComponent);
