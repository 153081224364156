import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Row } from 'components/simpleFlexbox';
import InputComponent from './InputComponent';
import { getNumberFormat } from 'resources/utilities';

function FormInputComponent({
    label,
    name,
    required,
    minLength,
    maxLength,
    pattern,
    validate,
    defaultValue,
    compareWithOther,
    showErrorMessageBelow,
    setErrors,
    formErrors,
    inputData,
    errorFlag,
    isValueLimited = false,
    limit = 0,
    lengthLimitClass,
    maxLimitExceededClass,
    ...otherProps
}) {
    const { errors, formState, register, setValue, getValues } = useFormContext();
    const isTouched = formState.touched[name] || formState.isSubmitted;
    const fieldError = errors[name];
    let errorMessage = isTouched && fieldError && fieldError.message;
    if (errorFlag !== undefined) {
        errorMessage = errorFlag ? errorMessage : undefined;
    }
    const props = otherProps;
    const [currentValueLength, setCurrentValueLength] = useState(0);
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    if (props.type === 'number') {
        props.step = 'any';
    }
    useEffect(() => {
        if (defaultValue && !isTouched) {
            setValue(name, defaultValue);
            setCurrentValueLength(defaultValue.length);
        }
    }, [defaultValue, isTouched, name, setValue]);

    useEffect(() => {
        if (setErrors !== undefined) {
            const newErrors = { ...formErrors };
            if (!(inputData['fieldName'] in newErrors)) {
                newErrors[inputData['fieldName']] = {};
            }
            if (!(inputData['index'] in newErrors[inputData['fieldName']])) {
                newErrors[inputData['fieldName']][inputData['index']] = {};
            }
            if (
                !(inputData['fieldType'] in newErrors[inputData['fieldName']][inputData['index']])
            ) {
                newErrors[inputData['fieldName']][inputData['index']][
                    inputData['fieldType']
                ] = false;
            }
            if (errorMessage !== undefined && errorMessage !== false) {
                newErrors[inputData['fieldName']][inputData['index']][
                    inputData['fieldType']
                ] = true;
            } else {
                newErrors[inputData['fieldName']][inputData['index']][
                    inputData['fieldType']
                ] = false;
            }
            setErrors(newErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage]);

    let validateProp;
    if (validate) {
        validateProp = {
            validate
        };
    }

    if (compareWithOther) {
        if (!validateProp) {
            validateProp = {};
        }
        validateProp.compareWithOther = (value) => {
            const values = getValues();
            return compareWithOther(value, values);
        };
    }

    function onChangeValue(e) {
        setValue && setValue(name, e.target.value);
        setCurrentValueLength(e.target.value.length);
    }

    const limitSpanClasses =
        isValueLimited &&
        currentValueLength !== undefined &&
        limit > 0 &&
        currentValueLength > limit
            ? lengthLimitClass + ' ' + maxLimitExceededClass
            : lengthLimitClass;

    return (
        <>
            <InputComponent
                type='text'
                onChange={(e) => onChangeValue(e)}
                name={name}
                id={name}
                defaultValue={defaultValue}
                label={label}
                ref={register({
                    required,
                    minLength,
                    maxLength,
                    pattern,
                    validate: validateProp
                })}
                errorMessage={errorMessage}
                showErrorMessageBelow={showErrorMessageBelow}
                {...props}
            />
            {isValueLimited && (
                <Row className={limitSpanClasses}>
                    {currentValueLength !== undefined ? getNumberFormat(currentValueLength) : 0}/
                    {getNumberFormat(limit)}
                </Row>
            )}
        </>
    );
}

export default FormInputComponent;
