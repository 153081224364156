import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import SLUGS from 'resources/slugs';
import { convertDisplayToYVar } from '../routes/campaignSizeRecommendations/utils/campaignSizeRecUtils';

function useCampaignSuggestionsFetchAll() {
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    return useQuery(['campaignSuggestions'], () => fetchAuth('/campaign_suggestions_v2/all'), {
        retry: false,
        refetchOnMount: false,
        onSuccess: (data) => {
            if (!data) {
                Sentry.captureException(new Error('empty result'), {
                    tags: ['campaign_suggestions']
                });
                push(SLUGS.error404);
            }
        },
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['campaign_suggestions']
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
}

const useCampaignSuggestion = (
    campaignType,
    appealType,
    variant,
    parentId,
    isCampaignInsightsData
) => {
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    const { data: campaignSuggestionsResponse, isLoading: isLoadingAll } =
        useCampaignSuggestionsFetchAll();
    const campaignSuggestions = campaignSuggestionsResponse?.data ?? [];

    const yVarCampaignType = convertDisplayToYVar(campaignType);
    const campaignTypeSearch = {
        campaignType: yVarCampaignType ?? null,
        appealType: appealType,
        variant: variant,
        parentId: parentId,
        isCampaignInsightsData: isCampaignInsightsData
    };
    const campaignTypeSearchParam = new URLSearchParams(campaignTypeSearch);
    const route = `/campaign_suggestions_v2/get?${campaignTypeSearchParam.toString()}`;

    const { data: campaignSuggestion, isLoading: isLoadingGet } = useQuery(
        [`campaignSuggestion_${campaignType}`, appealType, variant, parentId],
        () => fetchAuth(route),
        {
            retry: false,
            refetchOnMount: false,
            enabled: !isCampaignInsightsData,
            onSuccess: (data) => {
                if (!data) {
                    Sentry.captureException(new Error('empty result'), {
                        tags: ['campaign_suggestions']
                    });
                    push(SLUGS.error404);
                }
            },
            onError: (err) => {
                Sentry.captureException(err, {
                    tags: ['campaign_suggestions']
                });
                console.log(err);
                push(SLUGS.error404);
            }
        }
    );

    const data = JSON.parse(campaignSuggestion?.data ?? '{}');

    return {
        isLoading: isLoadingAll || isLoadingGet,
        data,
        campaignSuggestions
    };
};

export function useUpdateCampaignSuggestion(campaignType, variant) {
    const fetchAuth = useFetchAuth();
    const queryClient = useQueryClient();

    const post = (formValues) => {
        const yVarCampaignType = convertDisplayToYVar(formValues?.campaignType ?? null);
        const campaignTypeSearch = { campaignType: yVarCampaignType ?? null, variant };
        const campaignTypeSearchParam = new URLSearchParams(campaignTypeSearch);
        const route = `/campaign_suggestions_v2/update?${campaignTypeSearchParam.toString()}`;
        return fetchAuth(route, {
            method: 'POST',
            body: JSON.stringify(formValues)
        });
    };

    const {
        mutate: updateCampaignSuggestion,
        isLoading,
        error,
        data: updatedCampaignSuggestion
    } = useMutation(post, {
        onSuccess: () => {
            queryClient.refetchQueries(`campaignSuggestion_${campaignType}`);
            queryClient.refetchQueries('campaign_suggestions');
        }
    });

    const data = JSON.parse(updatedCampaignSuggestion?.data ?? '{}');

    return {
        data,
        error,
        isLoading,
        updateCampaignSuggestion
    };
}

export default useCampaignSuggestion;
