import React, { useRef, useState } from 'react';
import { any, func, object, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Column, Row } from 'components/simpleFlexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { useLogout } from 'hooks/auth/useAuth';
import useClientSettings from 'hooks/useClientSettings';
import useProducts from 'hooks/useProducts';
import LoadingComponent from 'components/loading';
import Popover from 'components/popover/PopoverComponent';
import ButtonComponent from 'components/form/ButtonComponent';
import { IconSignOut, IconNotes, IconCalendar } from 'assets/icons';

const useStyles = createUseStyles((theme) => ({
    arrow: {
        borderBottom: '10px solid transparent',
        borderRight: ({ theme }) => `10px solid ${theme.color.sidebarIconColor}`,
        borderTop: '10px solid transparent',
        height: 0,
        width: 0,
        position: 'absolute',
        left: -10,
        bottom: 16
    },
    subItemContainer: {
        backgroundColor: ({ theme }) => theme.color.softBalck,
        borderLeft: 'none !important',
        borderRadius: 10,
        cursor: 'default',
        left: 72,
        bottom: 'calc(0% - 14px)',
        padding: ({ hasItems }) => (hasItems ? '14px 18px 14px 14px' : '14px'),
        position: 'absolute',
        zIndex: 11
    },
    subItemList: {
        borderRadius: '0px 10px 10px 0px',
        minWidth: ({ hasItems }) => (hasItems ? 120 : 'none')
    },
    transition: {
        transition: 'all 0.2s ease-in-out'
    },
    userDataContainer: {
        padding: 6,
        width: 380
    },
    nameContainer: {
        background: 'white',
        borderRadius: '50%',
        fontSize: 22,
        height: 39,
        width: 39
    },
    userName: {
        fontWeight: 420,
        fontSize: 25,
        color: 'white'
    },
    userEmail: {
        fontWeight: 420,
        fontSize: 18,
        color: 'white'
    },
    switchButton: {
        outline: 'none',
        color: ({ theme }) => theme.color.dataroBlack,
        height: 22,
        padding: 0,
        paddingTop: 1,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10
    },
    separator: {
        borderBottom: '0.8px solid white',
        marginTop: 12,
        marginBottom: 22,
        maxWidth: '100%'
    },
    buttonContainer: {
        marginBottom: 12,
        paddingBottom: 2,
        cursor: 'pointer'
    },
    buttonText: {
        color: 'white',
        fontSize: 18,
        lineHeight: '26px',
        marginLeft: 14
    },
    upgradeAiAssistLink: {
        fontWeight: '400',
        fontSize: 18,
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            color: 'white',
            textDecoration: 'underline'
        }
    }
}));

function MenuItemComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const popoverRef = useRef(null);
    const settingsParams = [
        'first_name',
        'last_name',
        'email',
        'client_name',
        'client_name_external'
    ];
    const { data: { first_name, last_name, email, client_name, client_name_external } = {} } =
        useClientSettings({ params: settingsParams.join(',') });
    const {
        aiAssistDaysRemaining,
        hasAIAssistPaidUser,
        hasAIAssistPaidClient,
        isUsingDemoDataForPropensities,
        isUsingFIFree
    } = useProducts();

    const textName = `${(first_name || ' ')[0]}${(last_name || ' ')[0]}`;

    return (
        <Column
            key={'account'}
            id={`menu_account`}
            style={{ margin: '18px 0px 43px 0px' }}
            componentRef={(e) => {
                popoverRef.current = e;
            }}
        >
            <Row flexGrow={1} className={classes.transition}>
                <div />
                <Row flexGrow={1} id={`menu_account_icon_container`}>
                    <Row flexGrow={1} vertical='center' horizontal='center'>
                        <Row
                            vertical='center'
                            style={{ cursor: 'pointer', height: 25, position: 'relative' }}
                        >
                            <Row
                                vertical='center'
                                horizontal='center'
                                id={`menu_account_icon`}
                                className={classes.nameContainer}
                            >
                                {textName}
                            </Row>
                            <Popover itemRef={popoverRef} hideOnClose>
                                <div
                                    className={classes.subItemContainer}
                                    id={`menu_account_subitems`}
                                >
                                    <div className={classes.arrow} />
                                    <Column className={classes.subItemList}>
                                        <SubItemContent
                                            classes={classes}
                                            textName={textName}
                                            first_name={first_name}
                                            last_name={last_name}
                                            email={email}
                                            client_name_external={client_name_external}
                                            client_name={client_name}
                                            aiAssistDaysRemaining={aiAssistDaysRemaining}
                                            hasAIAssistPaid={
                                                hasAIAssistPaidClient || hasAIAssistPaidUser
                                            }
                                            isUsingDemoDataForPropensities={
                                                isUsingDemoDataForPropensities
                                            }
                                            isUsingFIFree={isUsingFIFree}
                                        />
                                    </Column>
                                </div>
                            </Popover>
                        </Row>
                    </Row>
                </Row>
            </Row>
        </Column>
    );
}

function SubItemContent({
    classes,
    textName,
    first_name,
    last_name,
    email,
    client_name_external,
    aiAssistDaysRemaining,
    hasAIAssistPaid,
    isUsingDemoDataForPropensities,
    isUsingFIFree
}) {
    const { push } = useHistory();
    const { logout } = useLogout();
    const [loading, setLoading] = useState(false);

    const showScheduleDemo = isUsingDemoDataForPropensities || isUsingFIFree;

    function handleSwitch() {
        push({ search: '?select_organization=true' });
    }

    async function handleLogout() {
        setLoading(true);
        await logout();
        Sentry.configureScope((scope) => scope.setUser(null));
        window.scrollTo(0, 0);
        localStorage.clear();
        window.location.reload();
    }

    function onUpgradeButtonClick() {
        window.open('https://buy.stripe.com/8wMbLD1dFcY186IeUW');
    }

    function onScheduleDemoClick() {
        push({ search: '?showCalendar=true' });
    }

    return (
        <>
            <Column className={classes.userDataContainer}>
                <Row>
                    <Row
                        vertical='center'
                        horizontal='center'
                        id={`menu_account_icon`}
                        className={classes.nameContainer}
                    >
                        {textName}
                    </Row>
                    <Column style={{ marginLeft: 18 }}>
                        <span className={classes.userName}>
                            {first_name} {last_name}
                        </span>
                        <span className={classes.userEmail} id='menu-acc-data-email'>
                            {email}
                        </span>
                    </Column>
                </Row>
                <Row vertical='center' style={{ marginTop: 14 }}>
                    <span className={classes.userEmail} id='menu-acc-data-client-name'>
                        {client_name_external}
                    </span>
                    <ButtonComponent
                        id='btn_switch'
                        className={classes.switchButton}
                        width={62}
                        height={26}
                        onClick={handleSwitch}
                    >
                        Switch
                    </ButtonComponent>
                </Row>
                <div className={classes.separator} />
                {!hasAIAssistPaid && (
                    <SubItemButton
                        classes={classes}
                        Icon={IconNotes}
                        id='menu_calendar'
                        text={
                            <Row>
                                {aiAssistDaysRemaining > 0
                                    ? `You have ${aiAssistDaysRemaining} days left of AI Assist`
                                    : 'Upgrade to AI Assist'}
                                <ButtonComponent
                                    id='btn_upgrade_now'
                                    className={classes.switchButton}
                                    width={90}
                                    height={26}
                                    onClick={onUpgradeButtonClick}
                                >
                                    Upgrade Now
                                </ButtonComponent>
                            </Row>
                        }
                        onClick={onUpgradeButtonClick}
                    />
                )}
                {showScheduleDemo && (
                    <SubItemButton
                        classes={classes}
                        Icon={IconCalendar}
                        id='menu_calendar'
                        text='Schedule a demo session'
                        onClick={onScheduleDemoClick}
                    />
                )}
                <SubItemButton
                    classes={classes}
                    Icon={IconSignOut}
                    id='menu_signout'
                    text='Sign Out'
                    onClick={handleLogout}
                />
            </Column>
            <LoadingComponent loading={loading} />
        </>
    );
}

function SubItemButton({ classes, Icon, id, text, onClick }) {
    return (
        <Row>
            <Row id={id} onClick={onClick} className={classes.buttonContainer}>
                <Icon height={25} width={25} fill='white' color='white' />
                <div className={classes.buttonText}>{text}</div>
            </Row>
        </Row>
    );
}

MenuItemComponent.propTypes = {
    children: any,
    icon: object,
    id: string,
    onClick: func,
    parentPath: string,
    title: string
};

export default MenuItemComponent;
